import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Box from '@material-ui/core/Box';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box padding={2} mdxType="Box">
      <p>{`Please read these User Terms & Conditions carefully. Please contact us if you have any questions before you use the Website. You can contact us at `}<a parentName="p" {...{
          "href": "mailto:helpdesk@talefin.com"
        }}>{`helpdesk@talefin.com`}</a></p>
      <p>{`This website is operated by TaleFin Australia Pty Ltd, and the terms "we", "us", "our" and "TaleFin" refer to TaleFin Australia Pty
Ltd and our group companies. TaleFin Australia Pty Ltd operates the site under license from TaleFin IP Pty Ltd. You
have no legal relationship with TaleFin IP Pty Ltd. The Website is available at banks.talefin.com (Website)
and may be available through other addresses or channels. The term "you" and "your" refers to users of the
Website (each a User).`}</p>
      <p>{`Thank you for using our Website (Services).`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "1-acceptance-of-terms",
        "style": {
          "position": "relative"
        }
      }}>{`1. ACCEPTANCE OF TERMS`}<a parentName="h3" {...{
          "href": "#1-acceptance-of-terms",
          "aria-label": "1 acceptance of terms permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`1.1`}</strong>{` You accept these User Terms by online acceptance, as indicated on our Website. Your use of the Website
is offered to you, conditional upon your acceptance without modification of (i) our Website Terms
of Use, (ii) our Privacy Policy, (iii) the terms, conditions, and notices set out below, and (iv)
any other terms, conditions, and notices which appear on the Website, as they exist at that time
(collectively, the User Terms).`}</p>
      <p><strong parentName="p">{`1.2`}</strong>{` Your access to and use of our Services indicates your acceptance of the User Terms. If you do
not accept the User Terms, you are not authorized to use the Website or our Services.`}</p>
      <p><strong parentName="p">{`1.3`}</strong>{` The User Terms may be amended without notice from time to time in our sole discretion. Your
continued use of our Services following any amendments indicates that you accept the amendments.
You should check the User Terms regularly, prior to using the Website, to ensure you are
aware of any changes, and only proceed to use the Website if you accept and will comply with
the new User Terms.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "2-term",
        "style": {
          "position": "relative"
        }
      }}>{`2. TERM`}<a parentName="h3" {...{
          "href": "#2-term",
          "aria-label": "2 term permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`2.1`}</strong>{` The User Terms begin on the date that you accept the User Terms by clicking the "I Accept"
button below, and will continue unless and until they are terminated in accordance with
the User Terms.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "3-services-and-fees",
        "style": {
          "position": "relative"
        }
      }}>{`3. SERVICES AND FEES`}<a parentName="h3" {...{
          "href": "#3-services-and-fees",
          "aria-label": "3 services and fees permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`3.1`}</strong>{` We agree to provide you with the Services set out on the Website, as soon as reasonably
possible after you accept the User Terms.`}</p>
      <p><strong parentName="p">{`3.2`}</strong>{` You do not pay any fees for using our Service. Fees are payable by the Institutions
that provide credit or credit assistance to users of our Website.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "4-user-information-and-license",
        "style": {
          "position": "relative"
        }
      }}>{`4. USER INFORMATION AND LICENSE`}<a parentName="h3" {...{
          "href": "#4-user-information-and-license",
          "aria-label": "4 user information and license permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`4.1`}</strong>{` You are responsible for providing, updating and maintaining, correct information
about yourself including your registration, usernames and account information
for third party web sites, and information that we access from third party
websites at your request and using your information (collectively, User Information).`}</p>
      <p><strong parentName="p">{`4.2`}</strong>{` By submitting User Information to TaleFin, you grant TaleFin and our
group companies a nonexclusive, royalty-free, perpetual, transferable,
irrevocable and fully sublicensable license to:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`use and disclose the User Information as set out in these User Terms, our Website Terms of Use and Privacy Policy; and`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`use and disclose the User Information to perform our Services, as set out in the User Terms.`}</p>
        </li>
      </ul>
      <p><strong parentName="p">{`4.3`}</strong>{` You grant TaleFin the right to pursue at law any person or
entity that violates your or TaleFin's rights in the User
Information including by a breach of the User Terms.`}</p>
      <p><strong parentName="p">{`4.4`}</strong>{` You are solely responsible for creating backup copies
of and replacing any User Information that you post or
store on the Website at your sole cost and expense.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "5-our-control-over-and-liability-for-user-information",
        "style": {
          "position": "relative"
        }
      }}>{`5. OUR CONTROL OVER AND LIABILITY FOR USER INFORMATION`}<a parentName="h3" {...{
          "href": "#5-our-control-over-and-liability-for-user-information",
          "aria-label": "5 our control over and liability for user information permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`5.1`}</strong>{` TaleFin takes no responsibility and assumes no liability
for any User Information provided by you or any third
party, including for inaccuracies or omissions, or
for any loss or damage thereto, nor is TaleFin liable
for any mistakes, defamation, slander, libel, omissions,
falsehoods, obscenity, pornography or profanity.
As a provider of interactive services, TaleFin is
not liable for any statements, representations or
User Information provided to TaleFin.`}</p>
      <p><strong parentName="p">{`5.2`}</strong>{` TaleFin reserves the right, and has sole and
absolute discretion, to use any User Information
provided to TaleFin or posted or stored on the
Website, as set out in the User Terms.`}</p>
      <p><strong parentName="p">{`5.3`}</strong>{` TaleFin has the right to include or exclude
entry to, or remove a User from the Website
at any time, for any reason whatsoever, in
its absolute discretion, without liability
to TaleFin.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "6-user-terms-of-use",
        "style": {
          "position": "relative"
        }
      }}>{`6. USER TERMS OF USE`}<a parentName="h3" {...{
          "href": "#6-user-terms-of-use",
          "aria-label": "6 user terms of use permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`6.1`}</strong>{`. As a condition of your use of this Website,
you warrant that:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`you possess the legal authority
to enter into the User Terms
and to use this Website in accordance
with the User Terms;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`you will act lawfully and will comply with any applicable laws,
regulations, industry codes of conduct and Australian standards in providing
information to us;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`all User Information provided by you to TaleFin is true, accurate, current
and complete;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`you will keep your User Information true, accurate, current and complete,
including to provide us with any new user name and passwords as required to
obtain the Services;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`you will only provide User Information that is owned by you, about
accounts owned by you where you are the account holder, and that you are
authorised to provide the User Information to us;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`where you provide information about jointly held accounts, you have
permission of the joint holders to provide the User Information to us on the
basis of the User Terms;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`where you provide a referral code, you are authorised and approved to use
the referral code;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`you will safeguard your TaleFin account details, including any username
and password, and will supervise and be responsible for any use of your
account by anyone other than you;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`you are the legal and beneficial owner or authorised licensee of all User
Information that you supply to us (whether or not for uploading onto the
Website);`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`if applicable, you have a valid ABN which has been advised to us; and`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`if applicable, you are registered
for GST purposes.`}</p>
        </li>
      </ul>
      <p><strong parentName="p">{`6.2`}</strong>{` As a condition of your use of this Website,
you agree:`}</p>
      <ul>
        <li parentName="ul">{`to abide by the User Terms;`}</li>
        <li parentName="ul">{`to provide true, accurate, current and complete User Information at all times`}</li>
        <li parentName="ul">{`not to post or upload User Information which contains hidden code or images which do not relate to or which alter the intended meaning of the original User Information; and`}</li>
        <li parentName="ul">{`that TaleFin has the right to refuse any and all current or future use of the Website. Where it is possible for TaleFin to contact you directly, we will notify you of any suspension or termination and the reasons for it.`}</li>
      </ul>
      <p><strong parentName="p">{`6.3`}</strong>{` In using the Website you:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`agree, if relevant, that you are
the registered business owner or
authorised representative of the
business using the Services;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`acknowledge that, to the extent permitted
by law, TaleFin is not liable for
any User Information that causes
you or your business any loss or
damage; and`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`acknowledge that TaleFin reserves
the right to include or exclude entry
to, or remove a User from the Website.`}</p>
        </li>
      </ul>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "7-prohibited-conduct",
        "style": {
          "position": "relative"
        }
      }}>{`7. PROHIBITED CONDUCT`}<a parentName="h3" {...{
          "href": "#7-prohibited-conduct",
          "aria-label": "7 prohibited conduct permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`7.1`}</strong>{` You will not:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Post, upload, distribute, store,
create or publish any message, data,
information, text, music, sound,
photos, graphics, code or any other
material :`}</p>
          <ul parentName="li">
            <li parentName="ul">
              <p parentName="li">{`i. that is false, unlawful, misleading,
libelous, defamatory, obscene,
pornographic, indecent, lewd,
suggestive, harassing or advocates
harassment of another person,
threatening, invasive of privacy
or publicity rights, abusive,
inflammatory, fraudulent or otherwise
objectionable;`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`ii. that may infringe any patent, trademark, trade secret, copyright or other
intellectual or proprietary right of any party. In particular, content that
promotes an illegal or unauthorised copy of another person's copyrighted work;`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`iii. that impersonates any person or entity or otherwise misrepresents your
affiliation with a person or entity, including TaleFin; and`}</p>
            </li>
            <li parentName="ul">
              <p parentName="li">{`iv. any material of which contains
computer worms, viruses or other
types of malicious or harmful
code;`}</p>
            </li>
          </ul>
        </li>
        <li parentName="ul">
          <p parentName="li">{`use the Website for any purpose or
in any way which is contrary to the
User Terms or which is unlawful,
including but not limited to breaching
intellectual property rights, linking
to a listing on the Website any defamatory,
threatening, harassing, abusive,
discriminatory, unlawful, obscene,
offensive, profane, indecent or otherwise
objectionable material of any kind;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`modify, copy, distribute, transmit, display, perform, reproduce, publish,
license, create derivative works from, transfer, or sell or re-sell any
information, software, products, or services obtained from or through this
Website, or do anything that compromises the security and/or stability of the
Website;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`use another person's name or identity without permission, or use the
Website while impersonating another person;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`use the Website in connection with the transmission of junk mail, spam,
chain letters or to engage in other flooding techniques or mass distribution
of unsolicited email;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`send automated queries to the Website without TaleFin's express written
permission`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`access, monitor or copy any content or information of this Website using
any robot, spider, scraper or other automated means or any manual process for
any purpose without our express written permission;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`violate the restrictions in any robot exclusion headers on this Website or
bypass or circumvent other measures employed to prevent or limit access to
this Website;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`take any action that imposes, or may impose, in our discretion, an
unreasonable or disproportionately large load on our infrastructure;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`deep-link to any portion of this Website for any purpose without our
express written permission;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`"frame", "mirror" or otherwise incorporate any part of this Website into
any other website without our prior written authorization; and`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`attempt to modify, translate, adapt, edit, decompile, disassemble, or
reverse engineer any software programs used by TaleFin in connection with the
Website.`}</p>
        </li>
      </ul>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "8-discontinuation-and-cancellation",
        "style": {
          "position": "relative"
        }
      }}>{`8. DISCONTINUATION AND CANCELLATION`}<a parentName="h3" {...{
          "href": "#8-discontinuation-and-cancellation",
          "aria-label": "8 discontinuation and cancellation permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`8.1`}</strong>{` We reserve the right to change or discontinue, temporarily or permanently, our Service at any time without notice. You
agree that we will not be liable to you or
any third party for any modification or discontinuance
of our Service.`}</p>
      <p><strong parentName="p">{`8.2`}</strong>{` You may cancel your registration by sending us a request for cancellation.
Upon confirmation of your request, your TaleFin account will be cancelled. We
reserve the right to cancel your account for extended periods of inactivity.`}</p>
      <p><strong parentName="p">{`8.3`}</strong>{` TaleFin may cancel our Service on 1 week's notice to you in writing
(including by email).`}</p>
      <p><strong parentName="p">{`8.4`}</strong>{` TaleFin may cancel the Services without notice, if you breach the Website
Terms of Use or these User Terms.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "9-obligations-on-termination",
        "style": {
          "position": "relative"
        }
      }}>{`9. OBLIGATIONS ON TERMINATION`}<a parentName="h3" {...{
          "href": "#9-obligations-on-termination",
          "aria-label": "9 obligations on termination permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`Upon termination of the User Terms:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`TaleFin will have no further obligation
to, and will cease to, perform the
Services to you;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`TaleFin has sole and absolute discretion on whether to remove all User
Information from its records, however it will retain copies of User
Information as required by law and regulatory authority, for the period
required;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`You will immediately return to TaleFin any property (including
Confidential Information and Intellectual Property) in your possession or
control, that belongs to us.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`The accrued rights, obligations and remedies of the Parties are not
affected by the termination of the User Terms.`}</p>
        </li>
      </ul>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "10-confidentiality",
        "style": {
          "position": "relative"
        }
      }}>{`10. CONFIDENTIALITY`}<a parentName="h3" {...{
          "href": "#10-confidentiality",
          "aria-label": "10 confidentiality permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`10.1`}</strong>{` You (and or any of your owners, directors,
managers, employees or agents) will maintain
the secrecy of any Confidential Information.
You (and or any of your owners, directors,
managers, employees or agents) agree that
you will not, and will use your best endeavours
to ensure third parties do not, disclose,
copy, transmit, retain or remove any Confidential
Information. The obligations under this clause
will survive termination of the User Terms.`}</p>
      <p><strong parentName="p">{`10.2`}</strong>{` Confidential Information means any information about TaleFin including
but not limited to its Intellectual Property, terms, operations, products,
prices, customers, code and algorithms, which is not in the public domain
(other than as a result of breach of confidence).`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "11-privacy",
        "style": {
          "position": "relative"
        }
      }}>{`11. PRIVACY`}<a parentName="h3" {...{
          "href": "#11-privacy",
          "aria-label": "11 privacy permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`11.1`}</strong>{` TaleFin will collect, use and disclose any personal
information in accordance with its Privacy Policy. The Privacy Policy is
available upon request or can be viewed on the Website. Users acknowledge our
Privacy Policy and consent to the collection, use and disclosure of personal
information in accordance with that Privacy Policy, the Australian Privacy
Principles as set out in the Privacy Act 1988 (Cth) and any other applicable
legislation or privacy guidelines.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "12-intellectual-property",
        "style": {
          "position": "relative"
        }
      }}>{`12. INTELLECTUAL PROPERTY`}<a parentName="h3" {...{
          "href": "#12-intellectual-property",
          "aria-label": "12 intellectual property permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`12.1`}</strong>{` All TaleFin Intellectual Property belongs
to TaleFin or its licensors. Users do not
obtain any interest or license in that Intellectual
Property. Users may not do anything which
interferes with or breaches those Intellectual
Property rights. TaleFin grants Users on
the Website permission only to use copyright
material as contemplated by the Services,
and not to use the content of the Website
in any other way or for any other purpose,
apart from fair dealing permitted by the
Copyright Act 1968.`}</p>
      <p><strong parentName="p">{`12.2`}</strong>{` Intellectual Property means all code, algorithms, copyright, fees,
pricing, trade marks, logos, slogans, designs, images, photographs, patents,
know-how, trade secrets, ideas, methods, diagrams, drawings, databases, notes,
documents, Confidential Information and any other proprietary or industrial
rights relating to TaleFin (in each case whether registered or unregistered or
whether capable of registration), together with any applications for
registration and any rights to registration or renewal of such rights anywhere
in the world and whether created before or after the date of these Terms;
registered and unregistered trademarks and service marks including goodwill in
the business and Services; trade, business company or organisation names;
internet domain names; and Website content, images and layout.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "13-moral-rights",
        "style": {
          "position": "relative"
        }
      }}>{`13. MORAL RIGHTS`}<a parentName="h3" {...{
          "href": "#13-moral-rights",
          "aria-label": "13 moral rights permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`13.1`}</strong>{` If it is determined that you retain moral
rights (including rights of attribution or
integrity) in the User Information, you hereby
declare that:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`You do not require that any personally
identifying information be used in
connection with the User Information,
or any derivative works of or upgrades
or updates thereto;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`You have no objection to the use, modification, deletion and exploitation
of the User Information by TaleFin or its licensees, successors and assigns;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`You forever waive and agree not to claim or assert any entitlement to any
and all moral rights of an author in any of the User Information;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`You unconditionally consent to any act or omission by TaleFin or persons
acting on behalf of TaleFin in relation to all User Information provided,
stored or uploaded by you (or in collaboration with others) in connection with
your use of or access to the Website, which may otherwise be an infringement
of your moral rights; and`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`You forever release TaleFin, and its licensees, successors and assigns,
from any claims that you could otherwise assert against TaleFin by virtue of
any such moral rights.`}</p>
        </li>
      </ul>
      <p><strong parentName="p">{`13.2`}</strong>{` The obligations under this clause will survive termination of the User
Terms.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "14-links",
        "style": {
          "position": "relative"
        }
      }}>{`14. LINKS`}<a parentName="h3" {...{
          "href": "#14-links",
          "aria-label": "14 links permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p>{`This Website may contain hyperlinks to websites operated by
parties other than TaleFin. These hyperlinks are provided for your reference
only. We do not control such websites and are not responsible for their
contents or the privacy or other practices of such websites. It is up to you
to take precautions to ensure that whatever links you select or software you
download (whether from this Website or other websites) is free of such items
as viruses, worms, trojan horses, defects and other items of a destructive
nature. Our inclusion of hyperlinks to such websites does not imply any
endorsement of the material on such websites or any association with their
operators.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "15-indemnities",
        "style": {
          "position": "relative"
        }
      }}>{`15. INDEMNITIES`}<a parentName="h3" {...{
          "href": "#15-indemnities",
          "aria-label": "15 indemnities permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`15.1`}</strong>{` Our services come with guarantees that cannot
be excluded under Australian Consumer Law.
You are entitled to a replacement or refund
for a major failure and compensation for
any other reasonably foreseeable loss or
damage. You are also entitled to have the
services provided again if the services fail
to be of acceptable quality and the failure
does not amount to a major failure. We provide
no warranty about the Services, except the
warranty that cannot be excluded under Australian
Consumer Law, to the extent that it applies,
that, throughout the Term that services will
be provided with due care and skill, will
be fit for the specified purpose and will
be supplied within a reasonable time. To
avoid doubt, all implied conditions or warranties
are excluded in so far as is permitted by
law, including (without limitation) warranties
of merchantability, fitness for purpose,
title and non-infringement.`}</p>
      <p><strong parentName="p">{`15.2`}</strong>{` You indemnify and hold harmless TaleFin,
our group companies and officers, directors,
employees and agents from and against any
claims, causes of action, demands, recoveries,
losses, damages, fines, penalties or other
costs or expenses of any kind or nature (including
but not limited to reasonable legal and accounting
fees) brought by third parties as a result
of:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`the inaccuracy, inadequacy or incompleteness
of your User Information;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`your breach of the User Terms, the Website Terms of Use, and any other
agreement between us;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`your infringement, or infringement by any other user of your account, of
any law or rights (including intellectual property rights) of TaleFin or a
third party; or`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`your, or any other user of your account, use of the Website or the
Services.`}</p>
        </li>
      </ul>
      <p><strong parentName="p">{`15.3`}</strong>{` The obligations under this clause will survive termination of the User Terms.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "16-liability-disclaimer",
        "style": {
          "position": "relative"
        }
      }}>{`16. LIABILITY DISCLAIMER`}<a parentName="h3" {...{
          "href": "#16-liability-disclaimer",
          "aria-label": "16 liability disclaimer permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`16.1`}</strong>{` The information, software, products, and
services published on this Website may include
inaccuracies or errors. To the maximum extent
permitted by law TaleFin and group companies
(collectively TaleFin) do not guarantee the
accuracy of, and disclaim all liability (including
for negligence) for any errors or other inaccuracies
relating to the information displayed on
this Website.`}</p>
      <p><strong parentName="p">{`16.2`}</strong>{` TaleFin makes no representations about the suitability of the
information, products, and services contained on this Website or any portion
thereof for any purpose. The inclusion or offering of any products or services
on this Website does not constitute any endorsement or recommendation of such
products or services by TaleFin. All such information, software, products, and
services are provided "as is" without warranty of any kind. TaleFin disclaims
all warranties and conditions that this Website, its servers or any email sent
from TaleFin, are free of viruses or other harmful components. To the maximum
extent permitted under applicable law TaleFin hereby disclaims all warranties
and conditions with regard to this information, software, products, and
services, including all implied warranties and conditions of merchantability,
fitness for a particular purpose, title, and noninfringement.`}</p>
      <p><strong parentName="p">{`16.3`}</strong>{` Nothing in these Terms shall exclude or limit TaleFin's liability for (i)
death or personal injury caused solely by TaleFin's negligence; (ii) TaleFin's
fraud; or (iii) TaleFin's wilful default or gross negligence (iv) any other
liability of TaleFin which cannot be excluded under applicable law.`}</p>
      <p><strong parentName="p">{`16.4`}</strong>{` We are committed to providing you with a
reliable service. We cannot however make
warranties or representations about the availability
of the Website or our Service. We take no
responsibility and assume no liability for
any outages of the Website. We reserve the
right to change or discontinue the Website
and/or our Services. You use the Website
at your own risk and in no event shall we,
our officers, directors and group companies,
be liable for any direct, indirect, punitive,
incidental, special, or consequential losses
or damages or any loss of income, profits,
goodwill, data, contracts, use of money,
or loss or damages arising from or connected
in any way to any:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`failure to store information;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`any outage, delay or inability to access, display or use the Website;`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`business interruption of any type, or`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`modification or discontinuance to the Services or the Website;`}</p>
        </li>
      </ul>
      <p>{`arising out of, or in any way connected with, your access to, display of or use of the Website or our Services, whether based
on a theory of negligence, contract, tort,
strict liability, or otherwise, and even
if TaleFin has been advised of the possibility
of such damages.`}</p>
      <p><strong parentName="p">{`16.5`}</strong>{` If, despite the limitations above, TaleFin is found liable for any loss
or damage which arises out of or in any way connected with any of the
occurrences described above, then to the maximum extent permitted by law the
liability of the relevant TaleFin company will in no event exceed, one months'
Fees.`}</p>
      <p><strong parentName="p">{`16.6`}</strong>{` The limitation of liability reflects the allocation of risk between the
parties. The limitations specified in this section will survive and apply even
if any limited remedy specified in these terms is found to have failed of its
essential purpose. The limitations of liability provided in these terms inure
to the benefit of TaleFin.`}</p>
    </Box>
    <Box padding={2} mdxType="Box">
      <h3 {...{
        "id": "17-general",
        "style": {
          "position": "relative"
        }
      }}>{`17. GENERAL`}<a parentName="h3" {...{
          "href": "#17-general",
          "aria-label": "17 general permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h3>
      <p><strong parentName="p">{`17.1`}</strong>{` You agree that any notices or other communications may be provided to you
electronically, and you agree to receive communications from us in electronic
form.`}</p>
      <p><strong parentName="p">{`17.2`}</strong>{` A notice or other communication must be in writing in English and may be
served to TaleFin personally by post, facsimile or email to TaleFin at the
address provided on our Website, to Contact Us. TaleFin may change its address
for service of notices by written notice on the Website.`}</p>
      <p><strong parentName="p">{`17.3`}</strong>{` A notice or notice or other communication must be in writing in English
and may be served to you personally by post, facsimile or email to you, using
the contact details provided by you. You may change your address for service
of notices by written notice to TaleFin.`}</p>
      <p><strong parentName="p">{`17.4`}</strong>{` Relationship of Parties: The User Terms are not intended to create a
relationship between the Parties of partnership, joint venture, agency or
employer-employee. You have no authority to create, assume or otherwise enter
into any agreement that imposes rights or obligations on the part of us.`}</p>
      <p><strong parentName="p">{`17.5`}</strong>{` Assignment: The User Terms are personal to the Parties. A Party must not
assign or deal with the whole or any part of its rights or obligations under
the User Terms without the prior written consent of the other Party (such
consent not to be unreasonably withheld). Any purported dealing in breach of
this clause is of no effect.`}</p>
      <p><strong parentName="p">{`17.6`}</strong>{` Waiver or Variation of Rights: Any failure or delay by a Party in
exercising a power or right (either wholly or partially) in relation to the
User Terms does not operate as a waiver or prevent that Party from exercising
that power or right or any other power or right. A Party is not liable to any
other Party for any loss, cost or expense that may have been caused or
contributed to by the failure, delay, waiver or exercise of a power or right.`}</p>
      <p><strong parentName="p">{`17.7`}</strong>{` Powers, Rights and Remedies: Except as expressly stated to the contrary
in the User Terms, the powers, rights and/or remedies of a Party under the
User Terms are cumulative and are in addition to any other powers, rights and
remedies of that Party. Nothing in the User Terms merges, extinguishes,
postpones, lessens or otherwise prejudicially affects any power, right, or
remedy that a Party may have at any time against the other Party to the User
Terms or any other person.`}</p>
      <p><strong parentName="p">{`17.8`}</strong>{` Execution and Counterparts: You may accept the User Terms by acceptance
in electronic form on the Website. You hereby agree to waive any rights to
challenge the validity or enforceability of the User Terms into on the Website`}</p>
      <p><strong parentName="p">{`17.9`}</strong>{` Entire Agreement and Understanding: In respect of the subject matter of
the User Terms the User Terms contains the entire understanding between the
Parties; all previous oral and written communications, representations,
warranties or commitments are superseded by the User Terms and do not affect
the interpretation or meaning of the User Terms and each of the Parties has
relied entirely on its own enquiries before entering into the User Terms.`}</p>
      <p><strong parentName="p">{`17.10`}</strong>{` Governing Law and Jurisdiction: The User Terms is governed by the laws
of New South Wales. Each party irrevocably and unconditionally submits to the
non‑exclusive jurisdiction of the courts of New South Wales.`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      